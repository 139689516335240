import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>ALOSANT PRIVACY POLICY: December 4, 2024</h1>

      <section>
        <h2>1. INTRODUCTION</h2>
        <p>
          This Privacy Policy describes how the “Blackwood Groves Owner Association” and the provider  of this app, Alosant (“Alosant”), treat data and information collected and received from you  through the application software that runs on mobile devices and tablets (“Applications”) and  any website delivering the Application (collectively, “Websites”), if such Website accompany  the Application. 
        </p>
        <p>
          Blackwood Groves Owner Association respects your privacy and is committed to treating your  information responsibly. To that end, Blackwood Groves Owner Association has developed this  Privacy Policy for the purpose of providing full disclosure concerning its collection, use and  sharing of information that is gathered during your interaction with the Applications and the  Websites. This Privacy Policy describes, among other things, the types of information collected  about you when you use the Applications and visit the Websites; how your information may be  used and when it may be disclosed; how you can control the use and disclosure of your  information; and how your information is protected. 
        </p>
        <p>
          The privacy and protection of your information is important to us. Although Blackwood Groves  Owner Association and Alosant endeavor to maintain appropriate physical and electronic  procedures and safeguards to protect personal information that is collected by and through the  Applications and the Websites, Blackwood Groves Owner Association and Alosant cannot guarantee the confidentiality of such  information. Accordingly, by using the Applications and the Websites, you understand that  (i) you are doing so at your own risk and (ii) while Blackwood Groves Owner Association and  Alosant will employ industry standard practices to protect your data, Blackwood Groves Owner  Association and Alosant cannot promise that collected information will never be disclosed in a  manner that is inconsistent with this Privacy Policy. 
        </p>
        <p>
          Should you have any questions regarding this Privacy Policy, or if you believe that Blackwood  Groves Owner Association or Alosant is not abiding by this Privacy Policy, please contact the  Privacy Administrator at the address set forth in Section 14, below.
        </p>
      </section>

      <section>
        <h2>2. SCOPE OF PRIVACY POLICY; ACCEPTANCE</h2>
        <p>
          This Privacy Policy applies to information collected about you - and provided by you through  your user account – by and through the Applications and the Websites. Your use of the  Applications and the Websites constitutes your consent to Blackwood Groves Owner  Association and Alosant for collection and use of such information in accordance with the terms  herein, except where obtaining your explicit permission is required. Each time you access,  browse or use the Websites or the Applications, you signify your acceptance of the Privacy  Policy (in its then-current form). If you do not accept this Privacy Policy, you are not authorized  to use the Applications or the Websites and must discontinue use of the Applications and the  Websites immediately. 
        </p>
      </section>

      <section>
        <h2>3. INFORMATION COLLECTED</h2>
        <p>Two (2) types of information are collected by and through the Applications and the Websites: Personally Identifiable Information and Non-Personal Information.</p>
        <h3>A. Personally Identifiable Information</h3>
        <p>
          Personally Identifiable Information is information  that tells Alosant specifics about who you are. The types of Personally Identifiable Information  that may be collected include, without limitation: your name and contact information (such as  your postal address, zip code, phone number and e-mail address); your gender; the number and/or  ages of members of your household; information associated with you relating to product  preferences; personal interest; and areas of interest on the Websites. You will be able to keep  private certain Personally Identifiable Information through settings in the Applications.
        </p>
        <p>
          Blackwood Groves Owner Association and Alosant may collect Personally Identifiable  Information from (and about) you in a variety of different ways. For example, when you register  on an Application or Website, you submit Personally Identifiable Information to the Blackwood  Groves Owner Association through Alosant software. Similarly, when you use the Applications  or the Websites to create or update a profile, register for events, or communicate or interact with  Alosant software and/or its representatives, you are providing Blackwood Groves Owner  Association and Alosant with Personal Information. 
        </p>
        <h3>B. Non-Personal Information</h3>
        <p>
          Non-Personal Information refers to data and  information that does not personally identify you as the individual to whom the data or  information relates. Non-Personal Information may include, without limitation:
        </p>
        <ul>
          <li>The IP address assigned to your computer (or other device);</li>
          <li>Your media access control (MAC) address;</li>
          <li>Information derived through Collection Technology (see Section 5.A, below);</li>
          <li>The type of computer (or other device) you use, and the operating system you use (e.g., Microsoft Windows or Mac OS);</li>
          <li>The type of Internet browser you use (e.g., Internet Explorer, Google Chrome or Safari);</li>
          <li>The domain name of your Internet Service Provider;</li>
          <li>Your activities while visiting or using the Websites or the Applications (including the content you access or view, the time of your visit to or use of the Applications or the Websites, and the pages you visit and the time spent on such pages); and</li>
          <li>Your public geographic location (such as your location within public areas of Blackwood Groves Owner Association). </li>
        </ul>
        <p>Alosant may choose to receive and record Non-Personal Information on its server logs from your  browser, as well as through other analytics in relation to the Applications and the Websites. Such  information will not be linked to Personally Identifiable Information without your permission.</p>
      </section>

      <section>
        <h2>4. USE AND DISCLOSURE OF INFORMATION </h2>
        <p>The information collected may vary depending upon how you use the Applications and the  Websites. Similarly, use and disclosure of the collected information will vary depending on the  type of information Blackwood Groves Owner Association and Alosant receives – i.e.,  Personally Identifiable Information vs. Non-Personal Information. </p>
        <h3>A. Personally Identifiable Information</h3>
        <p>
          The Parties may use your Personally Identifiable  Information to: 
        </p>
        <ul>
          <li>Carry out the purposes for which you provided the information;</li>
          <li>Keep you apprised of events, news and information that you may find of interest;</li>
          <li>Customize the content or functionality of the Applications and/or the Websites to your preferences;</li>
          <li>Attend to tasks relating to your interactions or transactions with the Applications or the Websites;</li>
          <li>Forward or otherwise provide updates, announcements and newsletters; and</li>
          <li>Respond to your communications, and communicate with you about the Applications, the Websites and activities related thereto.</li>
        </ul>
        <p>By using the Applications and/or the Websites, you authorize Blackwood Groves Owner  Association to transmit email and direct mail to you, call you, send push notifications to your  device, send you text messages, and otherwise contact you concerning these matters. Should you  not wish to receive communications, the Applications will be unable to deliver the services you  requested. </p>
        <p>District and GreenPointe Developers, LLC may intentionally disclose your Personally Identifiable  Information only in the following instances: </p>
        <h3>i. Disclosures You Have Requested.</h3>
        <p>
          Blackwood Groves Owner Association will provide your Personally Identifiable Information to those entities and individuals that you  have specifically designated (using the Applications, the Websites or otherwise) to receive your  information so that said entities and individuals can perform in accordance with your  directives/wishes, contact you to address your questions and requests, etc. However, Blackwood  Groves Owner Association is not responsible for the collection or maintenance of such  information once it is in the hands of the designated entit(y/ies) or individual(s). 
        </p>
        <h3>ii. Disclosures to Which You Have Consented</h3>
        <p>
          Blackwood Groves Owner  Association may disclose your Personally Identifiable Information if you have consented  (whether expressly or implicitly by installing the Applications or creating an account through the  Websites) to said disclosure. By way of example only, if (i) you elect to participate in an event  sponsored by Blackwood Groves Owner Association and (ii) the official rules of the event  declare that Blackwood Groves Owner Association may publish the names of (and other  information regarding) the event entrants, then, by virtue of your decision to participate in the  event, you are deemed to have consented to the event rules and the disclosure of your Personally  Identifiable Information in accordance with the rules. 
        </p>
        <h3>iii. Disclosures to Trusted Third Parties</h3>
        <p>
           Blackwood Groves Owner  Association may provide your Personally Identifiable Information to third parties who work on  behalf of or with Blackwood Groves Owner Association, provided that (i) the disclosure is  necessary to provide the service you requested or Blackwood Groves Owner Association provides through its operations and (ii) said operators work under confidentiality or similar  privacy agreements.
        </p>
        <h3>iv. Disclosures under Special Circumstances</h3>
        <p>
          Blackwood Groves Owner  Association and Alosant may store and disclose your Personally Identifiable Information (i) as  allowed or required by applicable law (including making disclosures in order to respond to  subpoenas, court orders or legal process) and (ii) to establish or exercise legal rights or defend  against legal claims as necessary or advisable to protect the rights, safety or property of  Blackwood Groves Owner Association, Alosant - or others - and to conform to legal or  regulatory requirements. Additionally, Blackwood Groves Owner Association and Alosant may  store and disclose your Personally Identifiable Information when Blackwood Groves Owner  Association or Alosant believes it is necessary to share such information in order to investigate,  prevent or take action regarding illegal activities, suspected fraud, situations involving potential  threats to the physical safety of any person, violations of the Terms of Use applicable to the  Application, the Websites, or as otherwise required by law. 
        </p>
        <h3>B. Non-Personal Information.</h3>
        <p>Alosant may use your Non-Personal Information in  several ways including, but not limited to:</p>
        <ul>
          <li>Administration of the Websites;</li>
          <li>Improvement of the Applications and the Websites (including navigation thereof);</li>
          <li>Improvement of the programs and services offered by Blackwood Groves Owner Association or Alosant; </li>
          <li>Analysis and development of advertisements and advertising campaigns;</li>
          <li>Providing you with special offers and promotions (and determinations regarding their effectiveness);</li>
          <li>Customization of the content or functionality of the Applications and the Websites to suit your preferences;</li>
          <li>Analysis of trends and usage.</li>
        </ul>
        <p>Alosant may use, share or disclose your Non-Personal Information in any other way it desires. For  example, Alosant may disclose your Non-Personal Information to employees, consultants and other  professionals who Alosant reasonably believes need such information in order to perform the tasks  described in this Section 4.B. Additionally, Alosant may combine your Non-Personal Information with other non-personal information collected by Alosant and share the  aggregated data with service companies and product providers for marketing and research purposes. </p>
      </section>

      <section>
        <h2>5. COLLECTION TECHNOLOGIES</h2>
        <h3>A. Collection Technology</h3>
        <p>
         Non-Personal Information may be collected by Alosant using a  variety of Collection Technology. As used in this Privacy Policy, “Collection  Technology” refers to cookies, web beacons, pixels, gifs, tags and other tools that collect  information in similar ways. Collection Technology may store a piece of data on your  browser or device, while other forms of Collection Technology may identify your  browser or device and collect information through other means. When you visit the  Applications or use the Websites, your browser or device may be exposed to Collection  Technology, and Collection Technology may be stored on your browser or device.  Alosant may access, retrieve and use the Non-Personal Information generated and stored  by the Collection Technology on your browser or device. Some Collection Technologies  may request you opt-in and such opt-in shall be at your sole discretion.
        </p>
        <p>
          You may delete and/or refuse the use of certain Collection Technology by selecting the  appropriate settings on your browser or choosing not to opt-in. And, you are not barred or  precluded from using the Websites or the Applications if you choose to delete and/or  refuse such Collection Technology. However, please note that some parts of the Websites  and/or the Applications may not work properly if you elect to delete and/or refuse  Collection Technology. 
        </p>
        <h3>B. Analytics Services</h3>
        <p>
          Alosant may use third-party analytics services to facilitate the  compilation, tracking and analysis of information that is accumulated using Collection  Technology. For example, Alosant may use Google Analytics to measure, analyze and report on  data gathered through Collection Technology to assist Alosant in understanding and optimizing  use of the Websites. Information and data collected through use of analytics services by Alosant  shall be used and shared by Alosant solely in conformance with this Privacy Policy. However,  please be advised that third-party analytics services are governed by their own privacy policies,  and neither Blackwood Groves Owner Association nor Alosant has control over the scope,  limitations or requirements of these policies. Should you have any questions regarding use of  analytics services by Alosant, please contact the Privacy Administrator at the address identified  in Section 14 of this Privacy Policy.
        </p>
      </section>

      <section>
        <h2>6. PROTECTION OF INFORMATION</h2>
        <p>
          Blackwood Groves Owner Association and Alosant are committed to protecting your Personal  Information. Accordingly, Alosant has adopted industry standard technical, administrative and  physical procedures to help protect your Personally Identifiable Information from loss, misuse,  unauthorized access and alteration. Please note, however, that no data transmission or storage can be guaranteed to be 100% secure (e.g., third party “hackers” may unlawfully intercept or  access information or confidential transmissions). Blackwood Groves Owner Association and  Alosant want you to feel confident using the Applications and the Websites; but, as indicated in  Section 1, above, Blackwood Groves Owner Association and Alosant cannot ensure or warrant  the security of any information you transmit to Blackwood Groves Owner Association and any  such transmission is done at your own risk. 
        </p>
      </section>

      <section>
        <h2>7. LINKS TO THIRD-PARTY WEBSITES</h2>
        <p>
          The Applications and/or the Websites may contain links to websites, applications, services,  notices and the like provided by other companies and/or individuals. These links are provided  solely as a convenience to viewers of the Websites and users of the Applications and not as an  endorsement of the contents of such links. Although Blackwood Groves Owner Association endeavors to provide links only to websites, etc. maintained by reputable entities and  organizations, you should be aware that these third parties might be able to gather information from and about you if you visit their websites, etc. In these cases, those third parties will use  such information in accordance with their own privacy policies, which may differ substantially  from this Privacy Policy. Blackwood Groves Owner Association and Alosant strongly encourage  you to read the privacy policies of these third parties. Alosant assumes no liability for the privacy  practices of such other websites, etc. and is not responsible for the availability or content of any  off-site pages or any other websites, etc. linked to the Applications or the Websites. Your  connecting to or visiting off-site pages or other websites, etc. is at your own risk. 
        </p>
      </section>

      <section>
        <h2>8. CORRECTION OF PERSONAL INFORMATION; OPT-OUT RIGHT </h2>
        <p>
          Blackwood Groves Owner Association and Alosant endeavor to keep your Personally  Identifiable Information current and accurate. Accordingly, Blackwood Groves Owner  Association with notice of any changes to your Personally Identifiable Information via e-mail,  telephone or postal mail at the addresses shown in Section 14, below. You may change or update  your Personally Identifiable Information through the Application Website or you may contact us  requesting such changes. 
          You may at any time opt-out of having your Personally Identifiable Information used by  Blackwood Groves Owner Association for mailings, calls, etc. by notifying Blackwood Groves  Owner Association via e-mail, telephone or postal mail at the addresses shown in Section 14,  below. If you elect not to receive any future contacts from Blackwood Groves Owner  Association, Blackwood Groves Owner Association will endeavor to remove your name from all  of its contact lists within ten (10) business days. However, information is sometimes difficult - if  not impossible - to remove from all data sources and, accordingly, Blackwood Groves Owner  Association and Alosant can only promise to use best practices within a reasonable period of  time to remove you from any lists that Blackwood Groves Owner Association or Alosant  controls. Alosant shall have no liability for our failure to accomplish this objective. 
        </p>
      </section>

      <section>
        <h2>9. CHILDREN</h2>
        <p>
          The safety of children is very important to Blackwood Groves Owner Association and Alosant,  and both are committed to protecting the privacy of children. In this regard, (i) the Applications and the Websites are directed to persons 18 years of age or older and (ii) Blackwood Groves  Owner Association does not knowingly collect information from children under the age of 18. If you provide Personally Identifiable Information to Blackwood Groves Owner Association through the Applications or the Websites, you represent and warrant to Blackwood Groves  Owner Association that you are 18 years of age or older. If you are under the age of 18, you may  visit, browse and use the information on the Applications or the Websites, but you may not  submit any Personally Identifiable Information to/through the Websites or the Applications and  you represent and warrant to Blackwood Groves Owner Association that you have the  permission of your parent or guardian to use the Applications and the Websites. If Blackwood  Groves Owner Association becomes aware that a user of the Websites and/or the Applications is  under the age of 18 and does not have permission of a parent or guardian, Blackwood Groves  Owner Association and Alosant will work together to remove all information about the user from  its files. 

        </p>
      </section>

      <section>
        <h2>10. NOTICE TO CALIFORNIA RESIDENTS – YOUR PRIVACY RIGHTS </h2>
        <p>
          Under California’s “Shine the Light Law” (Cal. Civ. Code Section 1798.83), California residents  are entitled to request certain information from Alosant about disclosure of personal information  to third parties for their direct marketing purposes. If you are a California resident, you may  make such a request by sending an e-mail or letter to Blackwood Groves Owner Association at  the address provide in Section 14.
        </p>
      </section>

      <section>
        <h2>11. PRIVACY POLICY EFFECTIVENESS </h2>
        <p>
          This Privacy Policy may be revised from time to time by Alosant, the District, or GreenPointe  Developers, LLC. The Privacy Policy displays a “Last Revised Date” on the upper left corner of the  Privacy Policy for you to know when there has been a change in the Privacy Policy. Accordingly, you  should check the Privacy Policy page or link on a regular basis for the most current version. Each time  you access, use or browse the Applications and/or the Websites, you signify your acceptance of the then current version of this Privacy Policy. Any changes in this Privacy Policy will take effect upon posting.  The Parties will work together to notify users of changes to the Privacy Policy. The Parties will use information collected by and through the Applications and the Websites in  accordance with the version of the Privacy Policy in effect as of the date the information was collected. If,  at any point, the Parties decide to use Personally Identifiable Information in a manner that is materially  different from that stated at the time it was collected, District and GreenPointe Developers, LLC will  provide you with notice or have other communications with you in the manner and to the extent required  by law. 
        </p>
      </section>

      <section>
        <h2>12. CHANGES IN PRIVACY POLICY</h2>
        <p>
         This Privacy Policy may be revised from time to time at the sole discretion of Alosant. The  Privacy Policy displays a “Last Revised Date” on the upper left corner of the Privacy Policy for  you to know when there has been a change in the Privacy Policy. Accordingly, you should check  the Privacy Policy page or link on a regular basis for the most current version. Each time you access, use or browse the Applications and/or the Websites, you signify your acceptance of the  then-current version of this Privacy Policy. Any changes in this Privacy Policy will take effect  upon posting. Blackwood Groves Owner Association and Alosant will work together to notify  users of changes to the Privacy Policy. Blackwood Groves Owner Association and Alosant will use information collected by and  through the Applications and the Websites in accordance with the version of the Privacy Policy  in effect as of the date the information was collected. If, at any point, Blackwood Groves Owner  Association and Alosant decide to use Personally Identifiable Information in a manner that is  materially different from that stated at the time it was collected, Blackwood Groves Owner  Association will provide you with notice or have other communications with you in the manner  and to the extent required by law. 
        </p>
      </section>

      <section>
        <h2>13. GOVERNING LAW</h2>
        <p>
          Nothing in this Privacy Policy shall be deemed as a waiver of immunity or limits of liability of the  District beyond any statutory limited waiver of immunity or limits of liability which may have been  adopted by the Florida Legislature in Section 768.28, Florida Statutes or other statute, and nothing in this  Privacy Policy shall inure to the benefit of any third party for the purpose of allowing any claim which  would otherwise be barred under the Doctrine of Sovereign Immunity or by operation of law. 
        </p>
      </section>
      <section>
        <h2>14. QUESTIONS OR CONCERNS</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy, please contact Alosant at: 
        </p>
        <p>
          Security Officer<br />
          Alosant<br />
          107 E. Oak Street <br />
          Bozeman, MT 59715<br />
          E-Mail: info@Alosant.com
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
